.logo {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 600;
  font-size: 1.5rem;
  letter-spacing: -0.01em;
  line-height: 1;
  user-select: none;
  cursor: pointer;
  margin: 0.8rem 0;
}

.code {
  color: #5e5e5e;
}

.logo:hover .code {
  color: #4e4e4e;
}

.dot {
  /* color: #828282; */
  color: #2380e2;
}

.rocks {
  color: #080808;
}

.logo:hover .rocks {
  color: #181818;
}
