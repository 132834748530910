.nav {
  display: flex;
  flex-direction: row;
}

.nav a {
  padding: 1rem 1.25rem;
  display: inline-flex;
  text-decoration: none;
  color: #616161;
  line-height: 1;
  justify-content: center;
  align-items: center;
}

.nav a:hover {
  color: #333;
  background: #f4f4f4;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    #f4f4f4 60%
  );
}

.navIcon {
  width: 1.5em;
  height: 1.5em;
  margin-right: 0.5em;
}
